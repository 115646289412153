import * as axios from "axios"
import * as _ from 'lodash'
import { PAYMENT_RESTRICTION, PREMIUM_RESTRICTIONS_URL } from './constants'

export const paymentIsAllowed = async instance => {
  const headers = { Authorization: instance, 'Content-Type': 'application/json' }
  const response = await axios.request({
    headers: headers,
    url: PREMIUM_RESTRICTIONS_URL,
  })
  const allowedFeatures = _.get(response,'data.allowedFeatures')
  return _.includes(allowedFeatures,PAYMENT_RESTRICTION)
}