import { ROLE_SUBMIT_BUTTON, ROLE_PREVIOUS_BUTTON, ROLE_NEXT_BUTTON } from '../constants/roles'
import {
  isCheckbox,
  isUploadButton,
  isCaptchaField,
  isDatePicker,
  shouldSendData,
} from './viewer-utils'
import * as _ from 'lodash'
import * as Raven from 'raven-js'

export const ERROR_TYPE_TAG_KEY = 'reason'

const withCatch = (func: Function, type: string) => {
  try {
    Raven.captureBreadcrumb({ message: type, category: 'submit-flow' })
    return func()
  } catch (err) {
    Raven.setTagsContext({ [ERROR_TYPE_TAG_KEY]: type })
    throw err
  }
}

const withCatchAsync = async (func: Function, type: string) => {
  try {
    Raven.captureBreadcrumb({ message: type, category: 'submit-flow' })
    return await func()
  } catch (err) {
    Raven.setTagsContext({ [ERROR_TYPE_TAG_KEY]: type })
    throw err
  }
}

export const DEFAULT_SUBMIT_ERROR = 'unknown client error'

export const SUBMIT_ERROR_TYPES = {
  GET_ATTACHMENTS: 'get attachments',
  VALIDATE_FIELDS: 'validate fields',
  GET_FIELDS: 'get fields',
  GET_COLLECTION_FIELDS: 'get collection fields',
  SEND_TO_SERVER: 'send to server',
  SEND_TO_WIX_DATA: 'send to wix data',
  RESET_FIELDS: 'reset fields',
  SEND_LEAD: 'send lead',
  NAVIGATE_NEXT_STEP: 'navigate next step',
  NAVIGATE_PREVIOUS_STEP: 'navigate previous step',
}

export const getSubmitButton = $w => $w(`@${ROLE_SUBMIT_BUTTON}`)[0]

export const getPreviousButtons = $w => $w(`@${ROLE_PREVIOUS_BUTTON}`)

export const getNextButtons = $w => $w(`@${ROLE_NEXT_BUTTON}`)

export const getFields = ({ $w, roles }) =>
  withCatch(() => {
    const fields = roles.reduce((res, roleField) => res.concat($w(`@${roleField}`)), [])
    return _.uniqBy(fields, (field: { uniqueId: string }) => field.uniqueId)
  }, SUBMIT_ERROR_TYPES.GET_FIELDS)

export const validateFields = ({ fields, strategy }): boolean =>
  withCatch(() => {
    fields.forEach(field => field.updateValidityIndication && field.updateValidityIndication())
    return strategy.validateFields(fields)
  }, SUBMIT_ERROR_TYPES.VALIDATE_FIELDS)

export const getAttachments = (
  fields
): Promise<{ url: string; name: string; uniqueId: string }[]> =>
  withCatchAsync(async () => {
    const uploadButtons = fields.filter(field => isUploadButton(field) && field.value.length > 0)

    return Promise.all(
      uploadButtons.map(async uploadButtonField => {
        const { url } = await uploadButtonField.startUpload()

        return {
          url,
          name: uploadButtonField.value[0].name,
          uniqueId: uploadButtonField.uniqueId,
        }
      })
    )
  }, SUBMIT_ERROR_TYPES.GET_ATTACHMENTS)

export const sendFieldsToServer = async ({
  strategy,
  attachments,
  fields,
  viewMode,
  experiments,
}) =>
  withCatchAsync(async () => {
    return await strategy.execute({ attachments, fields, viewMode, experiments })
  }, SUBMIT_ERROR_TYPES.SEND_TO_SERVER)

const getCurrentPageName = ({ wixSite, wixLocation }) => {
  const siteStructure = wixSite.getSiteStructure()

  const currentPath = wixLocation.path

  let currentPageName

  const validPageUrl = _.findLast(currentPath, url => !_.isEmpty(_.trim(url)))

  if (validPageUrl) {
    const currentPageStructure = _.find(siteStructure.pages, ['url', `/${validPageUrl}`])
    currentPageName = _.get(currentPageStructure, 'name')
  } else {
    const homePageStructure = _.find(siteStructure.pages, ['isHomePage', true])
    currentPageName = _.get(homePageStructure, 'name')
  }

  return currentPageName
}

export const sendWixAnalytics = ({ wixSite, wixLocation, wixWindow }) => {
  withCatch(() => {
    const currentPageName = getCurrentPageName({ wixSite, wixLocation })

    if (!currentPageName) return

    wixWindow.trackEvent('Lead', {
      label: `Page Name: ${currentPageName}`,
    })
  }, SUBMIT_ERROR_TYPES.SEND_LEAD)
}

export const resetFields = fields =>
  withCatch(() => {
    fields.forEach(field => {
      if (isUploadButton(field) || isCaptchaField(field)) {
        if ('reset' in field) {
          field.reset()
        }
        return
      }

      if (isCheckbox(field)) {
        field.checked = false
      } else {
        field.value = null
      }

      if ('resetValidityIndication' in field) {
        field.resetValidityIndication()
      }
    })
  }, SUBMIT_ERROR_TYPES.RESET_FIELDS)

export const navigateToStepByOffset = ($multiStepForm, offestIndex: number) => {
  const stateIdx = _.findIndex($multiStepForm.states, {
    uniqueId: $multiStepForm.currentState.uniqueId,
  })

  const nextState = $multiStepForm.states[stateIdx + offestIndex]

  if (nextState) {
    return $multiStepForm.changeState(nextState.id)
  }
}

export const navigateToNextStep = (
  { $nextButton, $multiStepForm },
  {
    onNavigationEnd,
    getCurrentFields,
    uploadFields,
    saveDatePickersInState,
    fillDatePickersInState,
    strategy,
    wixLocation,
    wixWindow,
  }
) =>
  withCatchAsync(async () => {
    $nextButton.disable()
    await onNavigationEnd
    const fields = getCurrentFields()
    const isStepValid = validateFields({
      fields,
      strategy,
    })

    if (isStepValid) {
      if (shouldSendData(wixLocation, wixWindow)) {
        await uploadFields(fields)
      }
      saveDatePickersInState()
      await navigateToStepByOffset($multiStepForm, 1)
      fillDatePickersInState()
    }

    $nextButton.enable()
  }, SUBMIT_ERROR_TYPES.NAVIGATE_NEXT_STEP)

export const navigateToPreviousStep = (
  { $previousButton, $multiStepForm },
  { onNavigationEnd, saveDatePickersInState, fillDatePickersInState }
) =>
  withCatchAsync(async () => {
    $previousButton.disable()
    await onNavigationEnd
    saveDatePickersInState()
    await navigateToStepByOffset($multiStepForm, -1)
    fillDatePickersInState()
    $previousButton.enable()
  }, SUBMIT_ERROR_TYPES.NAVIGATE_PREVIOUS_STEP)

export const registerFieldsToStates = (
  multiStepForm,
  fields: any[],
  onNavigationEnd: Promise<void>
): {
  getCurrentFields: () => any
  saveDatePickersInState: (fields: any[]) => void
  fillDatePickersInState: (fields: any[]) => void
} => {
  const statesFields: { [uniqueId: string]: any[] } = multiStepForm.states.reduce(
    (acc, { uniqueId }) => {
      acc[uniqueId] = []
      return acc
    },
    {}
  )

  fields.forEach(field => {
    field.onViewportEnter(async () => {
      await onNavigationEnd
      const currentStateId: string = multiStepForm.currentState.uniqueId
      const isFieldExists: boolean = !!_.find(statesFields[currentStateId], [
        'uniqueId',
        field.uniqueId,
      ])
      if (!isFieldExists) {
        statesFields[currentStateId] = statesFields[currentStateId].concat(field)
      }
    })
  })

  const datePickerValues: {
    [uniqueId: string]: string
  } = {}

  const saveDatePickersInState = () => {
    statesFields[multiStepForm.currentState.uniqueId]
      .filter(isDatePicker)
      .forEach(({ value, uniqueId }) => (datePickerValues[uniqueId] = value))
  }
  const fillDatePickersInState = () => {
    statesFields[multiStepForm.currentState.uniqueId]
      .filter(isDatePicker)
      .forEach(field => (field.value = datePickerValues[field.uniqueId]))
  }

  return {
    getCurrentFields: () => statesFields[multiStepForm.currentState.uniqueId],
    saveDatePickersInState,
    fillDatePickersInState,
  }
}

export const registerFieldsToCache = (): {
  uploadFields: (fields: any[]) => Promise<void>
  getUploadFieldsData: () => { url: string; name: string; uniqueId: string }[]
} => {
  const fieldsUploadData: {
    [uniqueId: string]: { url: string; name: string; uniqueId: string }
  } = {}
  const uploadFields = async fields => {
    const unsavedFields = fields.filter(({ uniqueId, value }) => {
      return (
        !fieldsUploadData[uniqueId] ||
        (value[0] && value[0].name !== fieldsUploadData[uniqueId].name)
      )
    })

    if (unsavedFields.length) {
      const attachments = await getAttachments(unsavedFields)

      attachments.forEach(attachment => {
        fieldsUploadData[attachment.uniqueId] = attachment
      })
    }
  }
  const getUploadFieldsData = () => _.values(fieldsUploadData)

  return {
    uploadFields,
    getUploadFieldsData,
  }
}
