import { FIELDS, ROLE_FORM, ROLE_SUBMIT_BUTTON } from '../../constants/roles'
import * as _ from 'lodash'
import { SecondsToResetDefaults, SuccessActionTypes } from '../../constants/success-settings'
import {
  isCaptchaField,
  isRadioGroup,
  isUploadButton,
  replaceMessageInnerText,
  toMiliseconds,
} from '../viewer-utils'
import { fetchRetry } from '../../utils/fetch-utils'
import { FormsFieldPreset } from '../../constants/field-types'
import { EmailConfig, Field, SubmitFormRequest } from '../../types/domain-types'
import { createFieldDto, getFieldValue } from '../field-dto/field-dto'
import { EMPTY_EMAIL_ID, isEmptyEmailId } from '../../utils/utils'
import { FORMS_APP_DEF_ID } from '../../constants'
import { navigateToStepByOffset } from '../submit-utils'

// TODO: Cover this file (registration form) with tests

const getBaseUrl = wixLocation => {
  const urlDirs = wixLocation.baseUrl.split('/')
  let baseUrl = urlDirs.slice(0, urlDirs.length - 1).join('/')
  if (baseUrl === 'https:/' || baseUrl === 'http:/') {
    baseUrl = wixLocation.baseUrl // TODO fix with amitay
  }

  return baseUrl
}

const getRecipients = (emailId, secondEmailId) => {
  let sendToOwner
  const emailIds = []

  if (_.isEmpty(emailId)) {
    // support backwards compatibility when emailId is empty string
    sendToOwner = true
  } else {
    if (emailId !== EMPTY_EMAIL_ID) {
      // send to other recipients
      emailIds.push(emailId)
    }
  }

  if (!isEmptyEmailId(secondEmailId)) {
    emailIds.push(secondEmailId)
  }

  return { sendToOwner, emailIds }
}

const createEmailConfig = (emailId, secondEmailId): EmailConfig => {
  const recipients = getRecipients(emailId, secondEmailId)
  const emailConfig: EmailConfig = { sendToEmails: { emailIds: [] } }

  if (recipients.sendToOwner) {
    emailConfig.sendToOwner = {} // equivalent to true in proto
  }

  emailConfig.sendToEmails.emailIds = [...recipients.emailIds]

  return emailConfig
}

const FILTERED_FIELDS = [FormsFieldPreset.GENERAL_RECAPTCHA]

const createFieldsDto = (fields, attachments) => {
  const fieldsDto = []

  const validFields = _.filter(
    fields,
    field => !_.includes(FILTERED_FIELDS, _.get(field, 'connectionConfig.fieldType'))
  )

  _.forEach(validFields, field => {
    const fieldDto: Field = createFieldDto(field, attachments)
    fieldsDto.push(fieldDto)
  })

  return fieldsDto
}

const EnrichPayloadWithCaptcha = ({ $w, payload }) => {
  const captchaField = $w(`@${FIELDS.ROLE_FIELD_RECAPTCHA}`)

  if (captchaField.length > 0) {
    const value = getFieldValue(captchaField)
    payload.security = { captcha: value }
  }
}

const sendActivity = ($w, { attachments, fields, wixLocation, wixWindow, instance }) => {
  const baseUrl = getBaseUrl(wixLocation)
  const url = `${baseUrl}/_api/wix-forms/v1/submit-form`
  const headers = { Authorization: instance, 'Content-Type': 'application/json' }

  const form = $w(`@${ROLE_FORM}`)
  const { emailId, secondEmailId, labels, formName = '' } = form.connectionConfig

  const fieldsDto: Field[] = createFieldsDto(fields, attachments)
  const emailConfig: EmailConfig = createEmailConfig(emailId, secondEmailId)

  const payload: SubmitFormRequest = {
    formProperties: {
      formName,
      formId: form.uniqueId,
    },
    emailConfig,
    viewMode: wixWindow.viewMode,
    fields: fieldsDto,
    labelIds: labels,
  }

  EnrichPayloadWithCaptcha({ $w, payload })

  return fetchRetry(url, {
    method: 'POST',
    headers,
    mode: 'cors',
    body: JSON.stringify(payload),
  })
}
const navigateToNextStep = ($w) => {
  const $multiStepForm = $w(`@${ROLE_FORM}`)
  return navigateToStepByOffset($multiStepForm, 1)
}

const showSuccessMessageIfExists = ({
  $message,
  secondsToResetForm,
  successActionType,
  successLinkValue,
  wixLocation,
  linksUtil,
  $w,
  isMultiStepForm,
}) => {
  switch (successActionType) {
    case SuccessActionTypes.LINK:
    case SuccessActionTypes.EXTERNAL_LINK:
      setTimeout(() => wixLocation.to(linksUtil.toUrl(successLinkValue)), 100)
      return Promise.resolve()

    case SuccessActionTypes.DOWNLOAD_DOCUMENT:
      if (_.get($message, 'html', undefined) === undefined) {
        return Promise.resolve()
      }
      replaceMessageInnerText(
        $message,
        innerText =>
          `<a href="${linksUtil.toUrl(
            successLinkValue,
          )}" target="_blank" role="alert">${innerText}</a>`,
      )
      $message.show()
      return isMultiStepForm ? navigateToNextStep($w) : Promise.resolve()

    default:
      const hasMessageContent = _.get($message, 'html', undefined) !== undefined
      if (hasMessageContent) {
        replaceMessageInnerText(
          $message,
          innerText => `<span role="alert">${innerText}</span>`,
        )
        $message.show()
      }

      if (isMultiStepForm) {
        return navigateToNextStep($w)
      }

      return (hasMessageContent &&
        secondsToResetForm >= SecondsToResetDefaults.MIN &&
        secondsToResetForm <= SecondsToResetDefaults.MAX) ?
        new Promise(resolve =>
        setTimeout(
          () => resolve($message.hide()),
          toMiliseconds(secondsToResetForm)
        )
      ) : Promise.resolve()
  }
}

export class FormStrategy {
  constructor(protected submitArgs, private linksUtil) {}

  static isEnabled($w) {
    return $w(`@${ROLE_SUBMIT_BUTTON}`)[0]
  }

  get instance() {
    return this.submitArgs.wixSite.getAppToken(FORMS_APP_DEF_ID)
  }

  validateFields(fields: any) {
    return _.filter(
      fields,
      field => !isUploadButton(field) || (field.required && field.value.length === 0),
    ).every(field => {
      if (isRadioGroup(field)) {
        // TODO - waiting for full fix for radioGroup
        return !field.required || field.value.length > 0
      }

      if (isCaptchaField(field)) {
        return !_.isEmpty(field.token)
      }

      if ('valid' in field) {
        return field.valid
      }
      return true
    })
  }

  async execute({ attachments, fields, skipSendActivity = false }): Promise<ServerResponse> {
    if (skipSendActivity) return Promise.resolve({ ok: true })

    const { $w, wixLocation, wixWindow } = this.submitArgs

    return sendActivity($w, {
      attachments,
      fields,
      wixLocation,
      wixWindow,
      instance: this.instance,
    })
  }

  async postSubmission() {
    const {
      $message,
      secondsToResetForm,
      successActionType,
      successLinkValue,
      wixLocation,
      isMultiStepForm,
      $w,
    } = this.submitArgs
    await showSuccessMessageIfExists({
      $w,
      isMultiStepForm,
      $message,
      secondsToResetForm,
      successActionType,
      successLinkValue,
      wixLocation,
      linksUtil: this.linksUtil,
    })
  }
}
